import { makeCancelable } from "./utils";

const BASE_API_URL =
  process.env.NODE_ENV !== "development"
    ? "/api/v1.0"
    : "http://localhost:5000/api/v1.0";

export const apiService = {
  login,
  logout,
  fetchMyUser,
  fetchMyJobs,
  fetchJob,
  createJob,
  patchJob,
  submitJob,
  uploadJobSTL,
  downloadJobSTL,
  getLegendUrl,
  BASE_API_URL
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
    credentials: "include"
  };

  const promise = fetch(`${BASE_API_URL}/logins/login`, requestOptions).then(
    response => {
      return response.json();
    }
  );

  return makeCancelable(promise);
}

function logout() {
  const promise = fetch(`${BASE_API_URL}/logins/logout`);
  return makeCancelable(promise);
}

function fetchMyUser(checkApiAuth) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include"
  };

  const promise = fetch(`${BASE_API_URL}/users/me`, requestOptions).then(
    response => {
      if (checkApiAuth) {
        checkApiAuth(response);
      }
      return response.json();
    }
  );

  return makeCancelable(promise);
}

function fetchMyJobs(checkApiAuth) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include"
  };

  const promise = fetch(`${BASE_API_URL}/jobs/me`, requestOptions).then(
    response => {
      if (checkApiAuth) {
        checkApiAuth(response);
      }
      return response.json();
    }
  );

  return makeCancelable(promise);
}

function fetchJob(jobId, checkApiAuth) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include"
  };

  const promise = fetch(`${BASE_API_URL}/jobs/${jobId}`, requestOptions).then(
    response => {
      if (checkApiAuth) {
        checkApiAuth(response);
      }
      return response.json();
    }
  );

  return makeCancelable(promise);
}

function createJob(job, checkApiAuth) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(job)
  };

  const promise = fetch(`${BASE_API_URL}/jobs`, requestOptions).then(
    response => {
      if (checkApiAuth) {
        checkApiAuth(response);
      }
      return response.json();
    }
  );

  return makeCancelable(promise);
}

function patchJob(job, checkApiAuth) {
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(job)
  };

  const promise = fetch(`${BASE_API_URL}/jobs/${job.id}`, requestOptions).then(
    response => {
      if (checkApiAuth) {
        checkApiAuth(response);
      }
      return response.json();
    }
  );

  return makeCancelable(promise);
}

function submitJob(jobId, checkApiAuth) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include"
  };

  const promise = fetch(
    `${BASE_API_URL}/jobs/${jobId}/submit`,
    requestOptions
  ).then(response => {
    if (checkApiAuth) {
      checkApiAuth(response);
    }
    return response.json();
  });

  return makeCancelable(promise);
}

function uploadJobSTL(jobId, stlFile, checkApiAuth) {
  const formData = new FormData();
  formData.append("file", stlFile);

  const requestOptions = {
    method: "POST",
    credentials: "include",
    body: formData
  };

  const promise = fetch(
    `${BASE_API_URL}/jobs/${jobId}/input`,
    requestOptions
  ).then(response => {
    if (checkApiAuth) {
      checkApiAuth(response);
    }
    return response.json();
  });

  return makeCancelable(promise);
}

function downloadJobSTL(stlUrl, checkApiAuth) {
  const requestOptions = {
    method: "GET",
    credentials: "include"
  };

  const promise = fetch(stlUrl, requestOptions).then(response => {
    if (checkApiAuth) {
      checkApiAuth(response);
    }
    return response.arrayBuffer();
  });

  return makeCancelable(promise);
}

function getLegendUrl(jobId, varname) {
  return `${BASE_API_URL}/jobs/${jobId}/legend/${varname}`;
}
