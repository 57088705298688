import React from "react";
import { Form, Button } from "react-bootstrap";

const MACHINE_TYPES = [
  "Direct Metal Laser Sintering (DMLS)",
  "Selective Laser Sintering (SLM)",
  "Electron Beam Melting"
];

class JobMachineTypeStep extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.manufacturingProcessFormSubmit}>
        <Form.Group>
          <Form.Label>MachineType</Form.Label>
          <Form.Control
            as="select"
            onChange={this.props.manufacturingProcessChange}
            value={this.props.manufacturingProcess}
            disabled={this.props.disabled}
          >
            {MACHINE_TYPES.map(machineType => (
              <option value={machineType} key={machineType}>
                {machineType}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button type="submit">Next</Button>
      </Form>
    );
  }
}

export default JobMachineTypeStep;
