import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserConsumer } from "./UserContext";
import { apiService } from "./apiService";

class PrivateRoute extends Component {
  cancelables = [];

  componentDidMount() {
    if (!this.props.userContext.user) {
      const cancelable = apiService.fetchMyUser();
      cancelable.promise
        .then(json => {
          if (json.user) {
            this.props.userContext.handleUserUpdate(json.user);
          }
        })
        .catch(err => {
          if (!err.isCanceled) {
            this.props.userContext.handleUserUpdate(null);
            console.log(err);
          }
        });
      this.cancelables.push(cancelable);
    }
  }

  componentWillUnmount() {
    this.cancelables.forEach(cancelable => {
      cancelable.cancel();
    });
  }

  render() {
    var { component: Component, userContext, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (userContext.user) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        }}
      />
    );
  }
}

export default function PrivateRouteWithUserContext(props) {
  return (
    <UserConsumer>
      {userContext => <PrivateRoute {...props} userContext={userContext} />}
    </UserConsumer>
  );
}
