import React, { Component } from "react";
import { Link } from "react-router-dom";
class Job extends Component {
  jobClick = event => {
    event.preventDefault();
    this.props.history.push(`/job/${this.props.job.id}`);
  };

  render() {
    const job = this.props.job;
    const creationDate = new Date(job.creation_date);

    return (
      <tr onClick={this.jobClick}>
        <td>
          <Link to={`/job/${job.id}`}>{job.id}</Link>
        </td>
        <td>
          <Link to={`/job/${job.id}`}>{job.name}</Link>
        </td>
        <td>
          <Link to={`/job/${job.id}`}>{job.material}</Link>
        </td>
        <td>
          <Link to={`/job/${job.id}`}>{job.manufacturing_process}</Link>
        </td>
        <td>
          <Link to={`/job/${job.id}`}>{creationDate.toLocaleString()}</Link>
        </td>
        <td>
          <Link to={`/job/${job.id}`}>{job.status}</Link>
        </td>
      </tr>
    );
  }
}

export default Job;
