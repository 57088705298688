class Field {
  constructor(jsonName, defaultValue) {
    this.jsonName = jsonName;
    this.defaultValue = defaultValue;
    this.stateName = jsonName.replace(/([_][a-z])/gi, $1 => {
      return $1.toUpperCase().replace("_", "");
    });
    this.setterName = `set${this.stateName.charAt(0).toUpperCase() +
      this.stateName.slice(1)}`;
    this.changeName = `${this.stateName}Change`;
  }
}
export const FIELDS = [
  new Field("name", ""),
  new Field("manufacturing_process", ""),
  new Field("tx", 0),
  new Field("ty", 0),
  new Field("tz", 0),
  new Field("mx", 0),
  new Field("my", 0),
  new Field("mz", 0),
  new Field("cz", 0),
  new Field("material", ""),
  new Field("structure", ""),
  new Field("poissons_ratio", 0),
  new Field("youngs_modulus", 0),
  new Field("t_ts", 0),
  new Field("yield_strength", 0),
  new Field("eps_x", 0),
  new Field("eps_y", 0),
  new Field("eps_z", 0),
  new Field("eps_xy", 0),
  new Field("eps_xz", 0),
  new Field("eps_yz", 0),
  new Field("volume_fraction", 0),
  new Field("le", 0),
  new Field("lb", 0)
];
