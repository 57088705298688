import React from "react";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor";
import vtkGenericRenderWindow from "vtk.js/Sources/Rendering/Misc/GenericRenderWindow";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper";
import vtkSTLReader from "vtk.js/Sources/IO/Geometry/STLReader";
import vtkAxesActor from "vtk.js/Sources/Rendering/Core/AxesActor";
import vtkOrientationMarkerWidget from "vtk.js/Sources/Interaction/Widgets/OrientationMarkerWidget";
import { apiService } from "../../apiService";

class STLViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();

    this.reader = vtkSTLReader.newInstance();
    this.renderWindow = vtkGenericRenderWindow.newInstance();

    global.renderWindow = this.renderWindow;

    var axesActor = vtkAxesActor.newInstance();

    this.orientationMarker = vtkOrientationMarkerWidget.newInstance({
      actor: axesActor,
      interactor: this.renderWindow.getRenderWindow().getInteractor()
    });
    this.orientationMarker.setEnabled(true);
    this.orientationMarker.setViewportCorner(
      vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
    );
    this.orientationMarker.setViewportSize(0.08);
    this.orientationMarker.setMinPixelSize(100);
    this.orientationMarker.setMaxPixelSize(300);
    this.orientationMarker.updateMarkerOrientation();
  }

  update = () => {
    const actor = vtkActor.newInstance();
    const mapper = vtkMapper.newInstance({ scalarVisibility: false });
    actor.setMapper(mapper);
    mapper.setInputConnection(this.reader.getOutputPort());

    const renderer = this.renderWindow.getRenderer();
    const renderWindow = this.renderWindow.getRenderWindow();

    const resetCamera = renderer.resetCamera;
    const render = renderWindow.render;

    renderer.addActor(actor);
    resetCamera();
    this.renderWindow.resize();
    render();
  };

  setStl = () => {
    if (this.props.stlUrl) {
      const cancelable = apiService.downloadJobSTL(this.props.stlUrl);
      cancelable.promise
        .then(blob => {
          this.reader.parseAsArrayBuffer(blob);
          this.update();
        })
        .catch(err => {
          if (!err.isCanceled) {
            console.log(err);
          }
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.stlFileName !== this.props.stlFileName) {
      this.setStl();
    }
  }

  componentDidMount() {
    const viewer = this.viewerRef.current;
    if (viewer) {
      this.renderWindow.setContainer(viewer);
      this.renderWindow.resize();
      this.setStl();
    }
    this.update();
  }

  render() {
    return (
      <div ref={this.viewerRef} style={{ height: "100%", maxHeight: "100%" }} />
    );
  }
}

export default STLViewer;
