import React from "react";

const UserContext = React.createContext({
  user: undefined,
  handleUserChange() {},
  checkApiAuth() {}
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
