import React, { Component } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import styles from "./NavBar.module.scss";
import logo from "../../public/ExL-Logo-Rebranddark.png";
import { apiService } from "../../apiService";
import { UserConsumer } from "../../UserContext";

class NavBar extends Component {
  cancelables = [];

  onLogoutClick = event => {
    event.preventDefault();
    const cancelable = apiService.logout();
    this.cancelables.push(cancelable);
    cancelable.promise.then(response => {
      if (response.status === 204) {
        document.cookie =
          "remember_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        this.props.userContext.handleUserUpdate(undefined);
      }
    });
  };

  componentWillUnmount() {
    this.cancelables.forEach(cancelable => {
      cancelable.cancel();
    });
  }

  render() {
    return (
      <Navbar bg="primary">
        <Image src={logo} alt="logo" fluid className={styles.navLogo} />
        <Navbar.Brand
          as={Link}
          to="/"
          className={styles.navlink}
          style={{ color: "white" }}
        >
          ExLattice
        </Navbar.Brand>
        {this.props.userContext.user ? (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/" className={styles.navlink}>
                Job List
              </Link>
              <NavLink to="/job" className={styles.navlink}>
                New Job
              </NavLink>
            </Nav>
            <Nav pullRight>
              <Link
                to="/login"
                onClick={this.onLogoutClick}
                className={styles.navlink}
                style={{ color: "" }}
              >
                Logout
              </Link>
            </Nav>
          </Navbar.Collapse>
        ) : null}
      </Navbar>
    );
  }
}

export default function NavBarWithUserContext(props) {
  return (
    <UserConsumer>
      {userContext => <NavBar {...props} userContext={userContext} />}
    </UserConsumer>
  );
}
