import React from "react";
import { Form, Button, Col } from "react-bootstrap";

class JobSimParametersStep extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.simParametersFormSubmit}>
        <Form.Group>
          <Form.Label>Orientation Angles (deg)</Form.Label>
          <Form.Row className="justify-content-center">
            <Col>
              <Form.Label>X</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.txChange}
                value={this.props.tx}
              />
            </Col>
            <Col>
              <Form.Label>Y</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.tyChange}
                value={this.props.ty}
              />
            </Col>
            <Col>
              <Form.Label>Z</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.tzChange}
                value={this.props.tz}
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Displacement (mm)</Form.Label>
          <Form.Row className="justify-content-center">
            <Col>
              <Form.Label>X</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.mxChange}
                value={this.props.mx}
              />
            </Col>
            <Col>
              <Form.Label>Y</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.myChange}
                value={this.props.my}
              />
            </Col>
            <Col>
              <Form.Label>Z</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.mzChange}
                value={this.props.mz}
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Cutting Position z (mm)</Form.Label>
          <Form.Control
            disabled={this.props.disabled}
            type="number"
            step="0.001"
            onChange={this.props.czChange}
            value={this.props.cz}
          />
        </Form.Group>
        <Button type="submit">Next</Button>
      </Form>
    );
  }
}

export default JobSimParametersStep;
