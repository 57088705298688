import React from "react";
import { Form, Button } from "react-bootstrap";

class JobNameStep extends React.Component {
  render() {
    console.log(`job name?? ${this.props.name}`);
    return (
      <Form onSubmit={this.props.nameFormSubmit}>
        <Form.Group>
          <Form.Label>Job Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Job Name"
            onChange={this.props.nameChange}
            value={this.props.name}
          />
        </Form.Group>
        <Button type="submit">
          {this.props.jobExists ? "Next" : "Create"}
        </Button>
      </Form>
    );
  }
}

export default JobNameStep;
