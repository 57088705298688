import React from "react";
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
import { apiService } from "../../apiService";
import { Route, Link } from "react-router-dom";
import JobNameStep from "../JobNameStep/JobNameStep";
import JobMachineTypeStep from "../JobMachineTypeStep/JobMachineTypeStep";
import JobSTLStep from "../JobSTLStep/JobSTLStep";
import JobMaterialPropertiesStep from "../JobMaterialPropertiesStep/JobMaterialPropertiesStep";
import JobSimParametersStep from "../JobSimParametersStep/JobSimParametersStep";
import JobMeshInformationStep from "../JobMeshInformationStep/JobMeshInformationStep";
import { debounce } from "../../utils";
import STLViewer from "../STLViewer/STLViewer";
import VTPViewer from "../VTPViewer/VTPViewer";
import styles from "./JobDetail.module.scss";
import { FIELDS } from "./Fields";
import { UserConsumer } from "../../UserContext";

class JobStep {
  constructor(displayName, routeName, component) {
    this.displayName = displayName;
    this.routeName = routeName;
    this.component = component;
  }

  toString() {
    return this.routeName;
  }
}
const NAME = new JobStep("Name", "name", JobNameStep);
const MACHINE_TYPE = new JobStep("Machine Type", "machine_type");
const STL = new JobStep("STL", "stl");
const SIMULATION_PARAMETERS = new JobStep(
  "Simulation Parameters",
  "simulation_parameters"
);
const MATERIAL_PROPERTIES = new JobStep(
  "Material Properties",
  "material_properties"
);
const MESH_INFORMATION = new JobStep("Mesh Information", "mesh_information");
const RESULTS = new JobStep("Results", "results");
const STEPS = [
  NAME,
  MACHINE_TYPE,
  STL,
  SIMULATION_PARAMETERS,
  MATERIAL_PROPERTIES,
  MESH_INFORMATION
];
const STEP_ROUTES = STEPS.map(step => {
  return step.routeName;
});

const TRANSIENT_JOB_STATES = ["queued", "running"];

class JobDetail extends React.Component {
  cancelables = [];

  constructor(props) {
    super(props);

    const state = {
      job: null,
      stlFile: null
    };

    FIELDS.forEach(field => {
      state[field.stateName] = field.defaultValue;
      this[field.changeName] = this[field.changeName].bind(this);
    });

    this.state = state;
  }

  pushStep = nextRoute => {
    this.props.history.push(
      this.props.match.params.jobId
        ? `${this.props.match.url}/${nextRoute}`
        : `${this.props.match.url}/${
            this.props.match.params.jobId
          }/${nextRoute}`
    );
  };

  createJob = name => {
    const cancelable = apiService.createJob(
      { name },
      this.props.userContext.checkApiAuth
    );
    cancelable.promise
      .then(json => {
        if (json.job) {
          this.props.history.replace(
            `${this.props.match.url}/${json.job.id}/${STEPS[0].routeName}`
          );
          this.props.history.push(
            `${this.props.match.url}/${json.job.id}/${STEPS[1].routeName}`
          );
        }
      })
      .catch(err => {
        if (!err.isCanceled) {
          console.log(err);
        }
      });
    this.cancelables.push(cancelable);
  };

  patchJob = (changedFields, nextRoute, updateLocal = true) => {
    changedFields["id"] = this.state.job.id;
    const cancelable = apiService.patchJob(
      changedFields,
      this.props.userContext.checkApiAuth
    );
    cancelable.promise
      .then(json => {
        if (json.job) {
          if (nextRoute) {
            this.pushStep(nextRoute);
          }
          if (updateLocal) {
            const state = { job: json.job };
            FIELDS.forEach(field => {
              if (field.jsonName in json.job) {
                state[field.stateName] = json.job[field.jsonName];
              }
            });
            this.setState(state);
          }
          this.startStatusPoll();
        }
      })
      .catch(err => {
        if (!err.isCanceled) {
          console.log(err);
        }
      });
    this.cancelables.push(cancelable);
  };

  startStatusPoll = () => {
    if (
      !this.jobPollIntervalId &&
      this.state.job &&
      TRANSIENT_JOB_STATES.includes(this.state.job.status)
    ) {
      this.jobPollIntervalId = setInterval(() => {
        if (this.state.job && this.state.job.id) {
          const cancelable = apiService.fetchJob(
            this.state.job.id,
            this.props.userContext.checkApiAuth
          );
          cancelable.promise
            .then(json => {
              if (json.job) {
                this.setState({ job: json.job });
                if (
                  !TRANSIENT_JOB_STATES.includes(this.state.job.status) &&
                  this.jobPollIntervalId
                ) {
                  clearInterval(this.jobPollIntervalId);
                }
              }
            })
            .catch(err => {
              if (!err.isCanceled) {
                console.log(err);
              }
            });
          // Canceling the previous poll if it hasnt finished in the interval
          if (this.jobPollCancelable) {
            this.jobPollCancelable.cancel();
          }
          this.jobPollCancelable = cancelable;
        }
      }, 3000);
    }
  };

  // Form submissions

  nameFormSubmit = event => {
    event.preventDefault();
    if (this.state.job) {
      this.patchJob({ name: this.state.name }, STEPS[1].routeName);
    } else {
      this.createJob(this.state.name);
    }
  };

  manufacturingProcessFormSubmit = event => {
    event.preventDefault();
    this.patchJob(
      { manufacturing_process: this.state.manufacturingProcess },
      STEPS[2].routeName
    );
  };

  stlSubmit = event => {
    event.preventDefault();
    this.pushStep(STEPS[3]);
  };

  simParametersFormSubmit = event => {
    event.preventDefault();
    this.patchJob({}, STEPS[4]);
  };

  materialPropertiesFormSubmit = event => {
    event.preventDefault();
    this.patchJob({}, STEPS[5]);
  };

  meshInformationFormSubmit = event => {
    event.preventDefault();
    if (this.state.job && this.state.job.status === "inactive") {
      this.onSubmitJob();
    } else if (this.state.job.status === "complete") {
      this.patchJob({}, RESULTS);
    }
  };

  stlChange = event => {
    event.preventDefault();
    const stlFile = event.target.files[0];
    this.setState({ stlFile });
    const cancelable = apiService.uploadJobSTL(
      this.state.job.id,
      stlFile,
      this.props.userContext.checkApiAuth
    );
    cancelable.promise
      .then(json => {
        if (json.job) {
          this.setState({ job: json.job });
        }
      })
      .catch(err => {
        if (!err.isCanceled) {
          console.log(err);
        }
      });
    this.cancelables.push(cancelable);
  };

  onSubmitJob = event => {
    if (event) {
      event.preventDefault();
    }
    const cancelable = apiService.submitJob(
      this.state.job.id,
      this.props.userContext.checkApiAuth
    );
    cancelable.promise
      .then(json => {
        if (json.job) {
          const state = { job: json.job };
          FIELDS.forEach(field => {
            if (field.jsonName in json.job) {
              state[field.stateName] = json.job[field.jsonName];
            }
          });
          this.setState(state);
          this.startStatusPoll();
        }
      })
      .catch(err => {
        if (!err.isCanceled) {
          console.log(err);
        }
      });
    this.cancelables.push(cancelable);
  };

  componentDidMount() {
    const jobId = this.props.match.params.jobId;
    if (jobId) {
      const cancelable = apiService.fetchJob(
        jobId,
        this.props.userContext.checkApiAuth
      );
      cancelable.promise
        .then(json => {
          if (json.job) {
            console.log(json.job);
            const state = { job: json.job };
            FIELDS.forEach(field => {
              if (field.jsonName in json.job) {
                state[field.stateName] = json.job[field.jsonName];
              }
            });
            this.setState(state);
            this.startStatusPoll();
          }
        })
        .catch(err => {
          if (!err.isCanceled) {
            console.log(err);
          }
        });
      this.cancelables.push(cancelable);
    }
  }

  componentWillUnmount() {
    this.cancelables.forEach(cancelable => {
      cancelable.cancel();
    });
    this.cancelables = [];
    if (this.jobPollIntervalId) {
      clearInterval(this.jobPollIntervalId);
    }
    if (this.jobPollCancelable) {
      this.jobPollCancelable.cancel();
    }
  }

  jobSubmitted = () => this.state.job && this.state.job.status !== "inactive";

  JobNameStepWrapper = props => (
    <JobNameStep
      {...props}
      jobExists={!!this.state.job}
      name={this.state.name}
      nameChange={this.nameChange}
      nameFormSubmit={this.nameFormSubmit}
    />
  );

  JobMachineTypeStepWrapper = props => (
    <JobMachineTypeStep
      {...props}
      disabled={this.jobSubmitted()}
      manufacturingProcess={this.state.manufacturingProcess}
      manufacturingProcessChange={this.manufacturingProcessChange}
      manufacturingProcessFormSubmit={this.manufacturingProcessFormSubmit}
    />
  );

  JobSTLStepWrapper = props => (
    <JobSTLStep
      {...props}
      disabled={this.jobSubmitted()}
      stlSubmit={this.stlSubmit}
      stlChange={this.stlChange}
    />
  );

  JobSimParametersStepWrapper = props => (
    <JobSimParametersStep
      {...props}
      disabled={this.jobSubmitted()}
      tx={this.state.tx}
      ty={this.state.ty}
      tz={this.state.tz}
      mx={this.state.mx}
      my={this.state.my}
      mz={this.state.mz}
      cz={this.state.cz}
      txChange={this.txChange}
      tyChange={this.tyChange}
      tzChange={this.tzChange}
      mxChange={this.mxChange}
      myChange={this.myChange}
      mzChange={this.mzChange}
      czChange={this.czChange}
      simParametersFormSubmit={this.simParametersFormSubmit}
    />
  );

  JobMaterialPropertiesStepWrapper = props => (
    <JobMaterialPropertiesStep
      {...props}
      disabled={this.jobSubmitted()}
      material={this.state.material}
      structure={this.state.structure}
      poissonsRatio={this.state.poissonsRatio}
      youngsModulus={this.state.youngsModulus}
      tTs={this.state.tTs}
      yieldStrength={this.state.yieldStrength}
      epsX={this.state.epsX}
      epsY={this.state.epsY}
      epsZ={this.state.epsZ}
      epsXy={this.state.epsXy}
      epsXz={this.state.epsXz}
      epsYz={this.state.epsYz}
      volumeFraction={this.state.volumeFraction}
      materialChange={this.materialChange}
      structureChange={this.structureChange}
      poissonsRatioChange={this.poissonsRatioChange}
      youngsModulusChange={this.youngsModulusChange}
      tTsChange={this.tTsChange}
      yieldStrengthChange={this.yieldStrengthChange}
      epsXChange={this.epsXChange}
      epsYChange={this.epsYChange}
      epsZChange={this.epsZChange}
      epsXyChange={this.epsXyChange}
      epsXzChange={this.epsXzChange}
      epsYzChange={this.epsYzChange}
      volumeFractionChange={this.volumeFractionChange}
      materialPropertiesFormSubmit={this.materialPropertiesFormSubmit}
    />
  );

  JobMeshInformationStepWrapper = props => (
    <JobMeshInformationStep
      {...props}
      disabled={this.jobSubmitted()}
      le={this.state.le}
      lb={this.state.lb}
      leChange={this.leChange}
      lbChange={this.lbChange}
      meshInformationFormSubmit={this.meshInformationFormSubmit}
    />
  );

  render() {
    console.log(
      `rendering job detail ${this.state.job && this.state.job.name}`
    );
    const jobId = this.props.match.params.jobId;
    const pathSegments = this.props.location.pathname.split("/");
    const lastPathSegment = pathSegments[pathSegments.length - 1];
    return (
      <Container style={{ height: "100%", maxHeight: "100%" }} fluid>
        <Row style={{ height: "100%" }}>
          <Col sm="auto" className={styles.sideBar}>
            {this.state.job && this.state.job.name ? (
              <h4>{this.state.job.name}</h4>
            ) : null}
            {this.state.job ? <h5>Status: {this.state.job.status}</h5> : null}
            <ListGroup className={styles.sideNav}>
              {STEPS.map(step => {
                const disabled = !jobId;
                const active =
                  (step === NAME &&
                    !STEP_ROUTES.concat([RESULTS.routeName]).includes(
                      lastPathSegment
                    )) ||
                  lastPathSegment === step.routeName;

                return (
                  <ListGroup.Item
                    as={Link}
                    key={step}
                    to={`${this.props.match.url}/${step.routeName}`}
                    action
                    eventKey={step}
                    disabled={disabled}
                    active={active}
                    className={active ? styles.selected : styles.notSelected}
                  >
                    {step.displayName}
                  </ListGroup.Item>
                );
              })}
              {this.state.job && this.state.job.status === "complete" ? (
                <ListGroup.Item
                  as={Link}
                  to={`${this.props.match.url}/${RESULTS.routeName}`}
                  action
                  eventKey={RESULTS}
                  active={lastPathSegment === RESULTS.routeName}
                  className={
                    lastPathSegment === RESULTS.routeName
                      ? styles.selected
                      : styles.notSelected
                  }
                >
                  {RESULTS.displayName}
                </ListGroup.Item>
              ) : null}
            </ListGroup>
            {this.state.job && this.state.job.status === "inactive" ? (
              <Button
                disabled={!this.state.job.input_file_url}
                onClick={this.onSubmitJob}
                className={styles.submitButton}
              >
                Submit
              </Button>
            ) : null}
            {this.state.job && this.state.job.status === "complete" ? (
              <Button
                href={`${apiService.BASE_API_URL}/jobs/${
                  this.state.job.id
                }/output`}
                className={styles.submitButton}
              >
                Download Output
              </Button>
            ) : null}
          </Col>
          <Col className={styles.detailsContainer}>
            <div
              style={{
                height: "100%",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {lastPathSegment === RESULTS.routeName ? (
                <div
                  style={{
                    flex: "1 1 0",
                    height: "100%",
                    maxHeight: "100%"
                  }}
                >
                  <VTPViewer
                    jobId={this.state.job && this.state.job.id}
                    vtpUrl={this.state.job && this.state.job.output_file_vtp}
                    baseUrl={
                      this.state.job &&
                      this.state.job.output_file_base_support_vtp
                    }
                  />
                </div>
              ) : (
                <React.Fragment>
                  <div
                    style={{
                      flex: "1 1 0",
                      maxHeight: "50%"
                    }}
                  >
                    <STLViewer
                      stlFileName={this.state.job && this.state.job.file_url}
                      stlUrl={this.state.job && this.state.job.input_file_url}
                    />
                  </div>
                  <div className={styles.formContainer}>
                    <Route
                      path={`${this.props.match.path}/${NAME.routeName}`}
                      component={this.JobNameStepWrapper}
                    />
                    <Route
                      path={this.props.match.path}
                      exact
                      component={this.JobNameStepWrapper}
                    />
                    <Route
                      path={`${this.props.match.path}/${
                        MACHINE_TYPE.routeName
                      }`}
                      component={this.JobMachineTypeStepWrapper}
                    />
                    <Route
                      path={`${this.props.match.path}/${STL.routeName}`}
                      component={this.JobSTLStepWrapper}
                    />
                    <Route
                      path={`${this.props.match.path}/${
                        SIMULATION_PARAMETERS.routeName
                      }`}
                      component={this.JobSimParametersStepWrapper}
                    />
                    <Route
                      path={`${this.props.match.path}/${
                        MATERIAL_PROPERTIES.routeName
                      }`}
                      component={this.JobMaterialPropertiesStepWrapper}
                    />
                    <Route
                      path={`${this.props.match.path}/${
                        MESH_INFORMATION.routeName
                      }`}
                      component={this.JobMeshInformationStepWrapper}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

FIELDS.forEach(field => {
  JobDetail.prototype[field.setterName] = debounce(function(value) {
    this.patchJob({ [field.jsonName]: value }, null, false);
  }, 250);
  JobDetail.prototype[field.changeName] = function(event) {
    event.preventDefault();
    const value = event.target.value;
    this.setState({ [field.stateName]: value });
    if (this.state.job && value) {
      this[field.setterName](value);
    }
  };
});

export default function JobDetailWithUserContext(props) {
  return (
    <UserConsumer>
      {userContext => <JobDetail {...props} userContext={userContext} />}
    </UserConsumer>
  );
}
