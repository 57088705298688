import React from "react";
import { Form, Button } from "react-bootstrap";

class JobMeshInformationStep extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.meshInformationFormSubmit}>
        <Form.Group>
          <Form.Label>Part voxel element size (mm)</Form.Label>
          <Form.Control
            disabled={this.props.disabled}
            type="number"
            step="0.001"
            onChange={this.props.leChange}
            value={this.props.le}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Base plate voxel element size (mm)</Form.Label>
          <Form.Control
            disabled={this.props.disabled}
            type="number"
            step="0.001"
            onChange={this.props.lbChange}
            value={this.props.lb}
          />
        </Form.Group>
        <Button type="submit">Next</Button>
      </Form>
    );
  }
}

export default JobMeshInformationStep;
