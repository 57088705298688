import React from "react";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor";
import vtkGenericRenderWindow from "vtk.js/Sources/Rendering/Misc/GenericRenderWindow";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper";
import vtkPolyDataReader from "vtk.js/Sources/IO/XML/XMLPolyDataReader";
import vtkAxesActor from "vtk.js/Sources/Rendering/Core/AxesActor";
import vtkOrientationMarkerWidget from "vtk.js/Sources/Interaction/Widgets/OrientationMarkerWidget";
import { apiService } from "../../apiService";
import { Form, Col, Button } from "react-bootstrap";
import {
  ColorMode,
  ScalarMode
} from "vtk.js/Sources/Rendering/Core/Mapper/Constants";
import vtkDataArray from "vtk.js/Sources/Common/Core/DataArray";
import vtkColorMaps from "vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps";
import vtkColorTransferFunction from "vtk.js/Sources/Rendering/Core/ColorTransferFunction";
import styles from "./VTPViewer.module.scss";

console.log(styles);

class VTPViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();

    this.renderWindow = vtkGenericRenderWindow.newInstance();

    this.vtpReader = vtkPolyDataReader.newInstance();
    this.vtpActor = vtkActor.newInstance();
    this.vtpMapper = vtkMapper.newInstance();
    this.vtpActor.setMapper(this.vtpMapper);

    this.baseReader = vtkPolyDataReader.newInstance();
    this.baseActor = vtkActor.newInstance();
    this.baseMapper = vtkMapper.newInstance({ scalarVisibility: false });
    this.baseActor.setMapper(this.baseMapper);

    this.activeArray = vtkDataArray;
    this.colorMapName = "Cool to Warm";
    this.lookupTable = vtkColorTransferFunction.newInstance();
    this.lookupTable.onModified(() => {
      this.renderWindow.getRenderWindow().render();
    });

    var axesActor = vtkAxesActor.newInstance();
    this.orientationMarker = vtkOrientationMarkerWidget.newInstance({
      actor: axesActor,
      interactor: this.renderWindow.getRenderWindow().getInteractor()
    });
    this.orientationMarker.setEnabled(true);
    this.orientationMarker.setViewportCorner(
      vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
    );
    this.orientationMarker.setViewportSize(0.08);
    this.orientationMarker.setMinPixelSize(100);
    this.orientationMarker.setMaxPixelSize(300);
    this.orientationMarker.updateMarkerOrientation();

    this.state = {
      modelVisible: true,
      baseVisible: true,
      colorBy: "PointData:Ut(mm)",
      colorByArrayName: "Ut(mm)",
      colorByOptions: [{ value: "", label: "Loading.." }]
    };
  }

  update = () => {
    this.vtpMapper.setInputConnection(this.vtpReader.getOutputPort());
    this.baseMapper.setInputConnection(this.baseReader.getOutputPort());

    const renderer = this.renderWindow.getRenderer();
    const renderWindow = this.renderWindow.getRenderWindow();

    const resetCamera = renderer.resetCamera;
    const render = renderWindow.render;

    renderer.addActor(this.vtpActor);
    renderer.addActor(this.baseActor);
    resetCamera();
    this.renderWindow.resize();
    render();

    this.activeArray = vtkDataArray;
    if (this.vtpSource) {
      const scalars = this.vtpSource.getPointData().getScalars();
      this.dataRange = [].concat(scalars ? scalars.getRange() : [0, 1]);
    }
  };

  setVtp = () => {
    if (this.props.vtpUrl) {
      const cancelable = apiService.downloadJobSTL(this.props.vtpUrl);
      cancelable.promise.then(blob => {
        this.vtpReader.parseAsArrayBuffer(blob);
        this.vtpSource = this.vtpReader.getOutputData();
        const cbo = this.vtpSource
          .getPointData()
          .getArrays()
          .map(a => ({
            label: `(p) ${a.getName()}`,
            value: `PointData:${a.getName()}`
          }))
          .concat(
            this.vtpSource
              .getCellData()
              .getArrays()
              .map(a => ({
                label: `(c) ${a.getName()}`,
                value: `CellData:${a.getName()}`
              }))
          );
        this.setState({
          colorByOptions: cbo
        });
        console.log(cbo);
        this.update();
        this.onColorChange({ target: { value: this.state.colorBy } });
      });
    }
  };

  setBase = () => {
    if (this.props.baseUrl) {
      const cancelable = apiService.downloadJobSTL(this.props.baseUrl);
      cancelable.promise.then(blob => {
        this.baseReader.parseAsArrayBuffer(blob);
        // Dont want just the base to pop up, so only update view if part is already loaded
        if (this.vtpSource) {
          this.update();
        }
      });
    }
  };

  applyPreset() {
    const preset = vtkColorMaps.getPresetByName(this.colorMapName);
    this.lookupTable.applyColorMap(preset);
    this.lookupTable.setMappingRange(this.dataRange[0], this.dataRange[1]);
    this.lookupTable.updateRange();

    // The above range setting isn't working, when using
    //    mapper.useLookupTableScalarRangeOn
    // the mapper should defer the range to the lut, but that's not happening
    // so we need to set the range explicitly on each mapper
    // FIXME: we should probably check the range on the supporting part too, but
    // this only uses the range from the lead part.
    this.vtpMapper.useLookupTableScalarRangeOff;
    this.vtpMapper.setScalarRange(this.dataRange);
    this.baseMapper.useLookupTableScalarRangeOff;
    this.baseMapper.setScalarRange(this.dataRange);
  }

  resetCamera = event => {
    if (event) {
      event.preventDefault();
    }
    var ren = this.renderWindow.getRenderer();
    ren.resetCamera();

    var fp = ren.getActiveCamera().getFocalPoint();
    var p = ren.getActiveCamera().getPosition();
    var dist = Math.sqrt(
      Math.pow(p[0] - fp[0], 2) +
        Math.pow(p[1] - fp[1], 2) +
        Math.pow(p[2] - fp[2], 2)
    );
    ren.getActiveCamera().setPosition(fp[0], fp[1], fp[2] + dist);
    ren.getActiveCamera().setViewUp(0, 1, 0);
    this.renderWindow.getRenderWindow().render();
  };

  onColorChange = event => {
    const colorBy = event.target.value;
    const [location, colorByArrayName] = colorBy.split(":");
    this.setState({ colorByArrayName, colorBy });
    const interpolateScalarsBeforeMapping = location === "PointData";
    let colorMode = ColorMode.DEFAULT;
    let scalarMode = ScalarMode.DEFAULT;
    const scalarVisibility = location.length > 0;
    if (scalarVisibility) {
      const newArray = this.vtpSource[`get${location}`]().getArrayByName(
        colorByArrayName
      );
      self.activeArray = newArray;
      if (self.activeArray) {
        const newDataRange = self.activeArray.getRange();
        this.dataRange[0] = newDataRange[0];
        this.dataRange[1] = newDataRange[1];
      }
      colorMode = ColorMode.MAP_SCALARS;
      scalarMode =
        location === "PointData"
          ? ScalarMode.USE_POINT_FIELD_DATA
          : ScalarMode.USE_CELL_FIELD_DATA;
    }
    this.vtpMapper.setColorByArrayName(colorByArrayName);
    this.vtpMapper.setColorMode(colorMode);
    this.vtpMapper.setInterpolateScalarsBeforeMapping(
      interpolateScalarsBeforeMapping
    );
    this.vtpMapper.setScalarMode(scalarMode);
    this.vtpMapper.setScalarVisibility(scalarVisibility);
    this.vtpMapper.setLookupTable(this.lookupTable);

    // Color the base too
    this.baseMapper.setColorByArrayName(colorByArrayName);
    this.baseMapper.setColorMode(colorMode);
    this.baseMapper.setInterpolateScalarsBeforeMapping(
      interpolateScalarsBeforeMapping
    );
    this.baseMapper.setScalarMode(scalarMode);
    this.baseMapper.setScalarVisibility(scalarVisibility);
    this.baseMapper.setLookupTable(this.lookupTable);

    this.applyPreset();
    this.renderWindow.getRenderWindow().render();
  };

  onModelVisibilityChecked = event => {
    this.setState({ modelVisible: event.target.checked });
    this.vtpActor.getProperty().setOpacity(event.target.checked ? 1 : 0);
    this.renderWindow.getRenderWindow().render();
  };

  onBaseVisibilityChecked = event => {
    this.setState({ baseVisible: event.target.checked });
    this.baseActor.getProperty().setOpacity(event.target.checked ? 1 : 0);
    this.renderWindow.getRenderWindow().render();
  };

  componentDidUpdate(prevProps) {
    console.log("VTPViewer did update");
    if (
      prevProps.vtpUrl !== this.props.vtpUrl ||
      prevProps.baseUrl !== this.props.baseUrl
    ) {
      this.setVtp();
      this.setBase();
    }
  }

  componentDidMount() {
    console.log("VTPViewer did mount");
    const viewer = this.viewerRef.current;
    if (viewer) {
      this.renderWindow.setContainer(viewer);
      this.renderWindow.resize();
      this.setVtp();
      this.setBase();
    }
    this.update();
  }

  render() {
    console.log(`VTPViewer render ${this.state.colorBy}`);

    return (
      <div
        style={{
          height: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Form className={styles.vtpControls}>
          <Form.Row className={styles.formRow}>
            <Form.Group as={Col} className={styles.formGroup} xs="auto">
              <Form.Control
                as="select"
                onChange={this.onColorChange}
                value={this.state.colorBy}
              >
                {this.state.colorByOptions.map(option => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} className={styles.formGroup} xs="auto">
              <Form.Check
                label="Model"
                checked={this.state.modelVisible}
                onChange={this.onModelVisibilityChecked}
              />
            </Form.Group>
            <Form.Group as={Col} className={styles.formGroup} xs="auto">
              <Form.Check
                label="Base/Support"
                checked={this.state.baseVisible}
                onChange={this.onBaseVisibilityChecked}
              />
            </Form.Group>
            <Form.Group as={Col} className={styles.formGroup}>
              <Button
                variant="primary"
                type="submit"
                onClick={this.resetCamera}
              >
                Reset
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <div
          ref={this.viewerRef}
          style={{
            flex: "1",
            maxHeight: "calc(100% - 50px)",
            display: "flex",
            flexBasis: "100%",
            position: "relative"
          }}
        >
          <img
            src={apiService.getLegendUrl(
              this.props.jobId,
              this.state.colorByArrayName
            )}
            alt="legend"
            style={{
              maxHeight: "90%",
              maxWidth: "30%",
              position: "absolute",
              bottom: "5%",
              right: "5%"
            }}
          />
        </div>
      </div>
    );
  }
}

export default VTPViewer;
