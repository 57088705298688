import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import styles from "./JobMaterialPropertiesStep.module.scss";

const MATERIALS = ["Al10SiMg", "Ti6Al4V", "In718", "In625"];
const STRUCTURES = ["cubic", "shell", "diagonal", "cross", "octet"];
class JobMaterialPropertiesStep extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.materialPropertiesFormSubmit}>
        <Form.Row className="justify-content-center">
          <Form.Group className={styles.formElement}>
            <Form.Label>Material</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              as="select"
              onChange={this.props.materialChange}
              value={this.props.material}
            >
              {MATERIALS.map(material => (
                <option value={material} key={material}>
                  {material}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className={styles.formElement}>
            <Form.Label>Support Structure</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              as="select"
              onChange={this.props.structureChange}
              value={this.props.structure}
            >
              {STRUCTURES.map(structure => (
                <option value={structure} key={structure}>
                  {structure}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className={styles.formElement}>
            <Form.Label>Poissons Ratio</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              type="number"
              step="0.001"
              onChange={this.props.poissonsRatioChange}
              value={this.props.poissonsRatio}
            />
          </Form.Group>
          <Form.Group className={styles.formElement}>
            <Form.Label>Youngs Modulus</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              type="number"
              step="0.001"
              onChange={this.props.youngsModulusChange}
              value={this.props.youngsModulus}
            />
          </Form.Group>
          <Form.Group className={styles.formElement}>
            <Form.Label>Tensile Strength</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              type="number"
              step="0.001"
              onChange={this.props.tTsChange}
              value={this.props.tTs}
            />
          </Form.Group>
          <Form.Group className={styles.formElement}>
            <Form.Label>Yield Strength</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              type="number"
              step="0.001"
              onChange={this.props.yieldStrengthChange}
              value={this.props.yieldStrength}
            />
          </Form.Group>
          <Form.Group className={styles.formElement}>
            <Form.Label>Volume Fraction</Form.Label>
            <Form.Control
              disabled={this.props.disabled}
              type="number"
              step="0.001"
              onChange={this.props.volumeFractionChange}
              value={this.props.volumeFraction}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Label>Inherent Strain</Form.Label>
          <Form.Row>
            <Col>
              <Form.Label>X</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.epsXChange}
                value={this.props.epsX}
              />
            </Col>
            <Col>
              <Form.Label>Y</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.epsYChange}
                value={this.props.epsY}
              />
            </Col>
            <Col>
              <Form.Label>Z</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.epsZChange}
                value={this.props.epsZ}
              />
            </Col>
            <Col>
              <Form.Label>XY</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.epsXyChange}
                value={this.props.epsXy}
              />
            </Col>
            <Col>
              <Form.Label>XZ</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.epsXzChange}
                value={this.props.epsXz}
              />
            </Col>
            <Col>
              <Form.Label>YZ</Form.Label>
              <Form.Control
                disabled={this.props.disabled}
                type="number"
                step="0.001"
                onChange={this.props.epsYzChange}
                value={this.props.epsYz}
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <Button type="submit">Next</Button>
      </Form>
    );
  }
}

export default JobMaterialPropertiesStep;
