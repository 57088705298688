import React, { Component } from "react";
import Job from "../Job/Job";
import Table from "react-bootstrap/Table";
import { apiService } from "../../apiService";
import styles from "./JobList.module.scss";
import { UserConsumer } from "../../UserContext";

class JobList extends Component {
  cancelables = [];

  constructor(props) {
    super(props);
    this.state = {
      jobs: []
    };
  }

  componentDidMount() {
    const cancelable = apiService.fetchMyJobs(
      this.props.userContext.checkApiAuth
    );
    cancelable.promise
      .then(json => {
        console.log(json);
        if (json.jobs) {
          this.setState({
            jobs: json.jobs
          });
        }
      })
      .catch(err => {
        if (!err.isCanceled) {
          console.log(err);
        }
      });
    this.cancelables.push(cancelable);
  }

  componentWillUnmount() {
    this.cancelables.forEach(cancelable => {
      cancelable.cancel();
    });
    this.cancelables = [];
  }

  render() {
    console.log("JobList render");
    return (
      <div className={styles.jobList}>
        <Table striped bordered hover className={styles.jobTable}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Material</th>
              <th>Manufacturing Process</th>
              <th>Creation Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {this.state.jobs.map(job => {
              return (
                <Job key={job.id} job={job} history={this.props.history} />
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default function JobListWithUserContext(props) {
  return (
    <UserConsumer>
      {userContext => <JobList {...props} userContext={userContext} />}
    </UserConsumer>
  );
}
