import React, { Component } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { UserConsumer } from "../../UserContext";
import { apiService } from "../../apiService";
import { Redirect } from "react-router-dom";
import logo from "../../public/ExL-Logo-Rebranddark-2.png";
import styles from "./Login.module.scss";

export class Login extends Component {
  cancelables = [];

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      email: "",
      password: "",
      failure: false
    };
  }

  logIn = event => {
    event.preventDefault();
    const cancelable = apiService.login(this.state.email, this.state.password);
    this.setState({ failure: false });

    cancelable.promise
      .then(json => {
        console.log("user: " + JSON.stringify(json));
        if (json.user) {
          this.props.userContext.handleUserUpdate(json.user);
          this.setState({ loggedIn: true });
        } else {
          this.setState({ failure: true });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ failure: true });
      });

    this.cancelables.push(cancelable);
  };

  componentWillUnmount() {
    this.cancelables.forEach(cancelable => {
      cancelable.cancel();
    });
  }

  render() {
    console.log("Login, logged in? " + !!this.props.userContext.user);
    return (
      <div>
        {this.props.userContext.user ? (
          <Redirect
            to={
              (this.props.location.state && this.props.location.state.from) ||
              "/"
            }
          />
        ) : null}
        <div className={styles.loginContainer}>
          <Image src={logo} alt="logo" fluid className={styles.loginLogo} />
          <Form onSubmit={this.logIn}>
            <Form.Group>
              <Form.Label className={styles.loginFieldTitle}>
                Email Address
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={event => {
                  this.setState({ email: event.target.value });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className={styles.loginFieldTitle}>
                Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={event => {
                  this.setState({ password: event.target.value });
                }}
              />
              {this.state.failure ? (
                <Form.Label className={styles.errorMessage}>
                  Email or password invalid
                </Form.Label>
              ) : null}
            </Form.Group>

            <Button
              variant="light"
              type="submit"
              className={styles.loginButton}
            >
              Log In
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default function LoginWithUserContext(props) {
  return (
    <UserConsumer>
      {userContext => <Login {...props} userContext={userContext} />}
    </UserConsumer>
  );
}
