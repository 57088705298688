import React from "react";
import { Form, Button } from "react-bootstrap";

class JobSTLStep extends React.Component {
  render() {
    return (
      <Form onSubmit={this.props.stlSubmit}>
        <Form.Group>
          <Form.Label>STL File</Form.Label>
          <Form.Control
            disabled={this.props.disabled}
            type="file"
            onChange={this.props.stlChange}
          />
        </Form.Group>
        <Button type="submit">Next</Button>
      </Form>
    );
  }
}

export default JobSTLStep;
