import React, { Component } from "react";
import "./App.css";
import JobList from "../JobList/JobList";
import JobDetail from "../JobDetail/JobDetail";
import NavBar from "../NavBar/NavBar";
import Login from "../Login/Login";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { UserProvider } from "../../UserContext";
import PrivateRoute from "../../PrivateRoute";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      handleUserUpdate: this.handleUserUpdate,
      checkApiAuth: this.checkApiAuth
    };
  }

  handleUserUpdate = user => {
    this.setState({ user });
  };

  checkApiAuth = response => {
    if (response.status === 401) {
      this.setState({ user: undefined });
    }
  };

  render() {
    return (
      <div
        className="App"
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <UserProvider value={this.state}>
          <Router basename="/app">
            <NavBar style={{ flex: "0 1 auto" }} />
            <div style={{ flex: "1", minHeight: "0" }}>
              <Route path="/login" component={Login} />
              <PrivateRoute
                path="/"
                exact
                userContext={this.state}
                component={JobList}
              />
              <PrivateRoute path="/job/:jobId" component={JobDetail} />
              <PrivateRoute exact path="/job/" component={JobDetail} />
            </div>
          </Router>
        </UserProvider>
      </div>
    );
  }
}

export default App;
